import { observable, action, decorate } from "mobx";

class Store {
	userDetails = null;
	transports = [];
	questions = [];
	properties = [];
	events = [];
	competitions = [];
	filteredData = [];
	questions = [];
	homescreenCards = [];
	members = [];
	pending = [];


	getHomescreenCard(id){
		return this.homescreenCards.find((e)=>e.home_screen_id===id);
	}
	setFilteredData(filteredData) {
		this.filteredData = filteredData.map((x, i) => { x.id = i; return x; });
	}

	setHomescreeenCards(cards){
		this.homescreenCards = cards;
	}

	setUserDetails(userDetails) {
		this.userDetails = userDetails;
	}
	setEvents(events) {
		this.events = events;
	}
	setCompetitions(competitions) {
		this.competitions = competitions;
	}
	setTransports(transports) {
		this.transports = transports;
	}
	setProperties(properties) {
		this.properties = properties;;
	}
	setQuestions(newQuestions) {
		this.questions = newQuestions;
	}
	setMembers(members){
		this.members = members;
	}
	setPending(members){
		this.pending = members;
	}
}

decorate(Store, {
	homescreenCards: observable,
	userDetails: observable,
	competitions: observable,
	members: observable,
	pending: observable,
	setHomescreeenCards: action,
	setCompetitions: action,
	setMembers: action,
	setPending: action,
});

const storeInstance = new Store();
export default storeInstance;
