import React from "react"
import {
	UncontrolledDropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
} from "reactstrap"

import * as Icon from "react-feather"
import { history } from "../../../history"
import { getUserDetails, logout } from "../../../api";

const UserDropdown = props => {

	async function logOut() {
		const logoutSuccess = await logout();
		if (!logoutSuccess) {
			return null;
		}
		history.push("/");
	}

	return (
		<DropdownMenu right>
			<DropdownItem
				tag="a"
				href="#"
				onClick={()=>logOut()}
			>
				<Icon.Power size={14} className="mr-50" />
				<span className="align-middle">Log Out</span>
			</DropdownItem>
		</DropdownMenu>
	)

};

class NavbarUser extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			navbarSearch: false,
			suggestions: [],
		}
	}

	render() {
		const details = getUserDetails();
		return (
			<ul className="nav navbar-nav navbar-nav-user float-right">

				<UncontrolledDropdown tag="li" className="dropdown-user nav-item">
					<DropdownToggle tag="a" className="nav-link dropdown-user-link">
						<div className="user-nav d-sm-flex d-none">
							<span className="text-bold-600">
								{details && `${details.first_name} ${details.last_name}`}
							</span>
							{/*<span className="user-status">Role</span>*/}
						</div>
						<span data-tour="user">
							<img
								src={this.props.userImg}
								className="round"
								height="40"
								width="40"
								alt="avatar"
							/>
						</span>
					</DropdownToggle>
					<UserDropdown {...this.props} />
				</UncontrolledDropdown>
			</ul>
		)
	}

}

export default NavbarUser;
