import React, { lazy, Suspense } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { store } from "./redux/storeConfig/store"
import "./index.scss"
import "./@fake-db"
import storeInstance from './store'

export const StoreContext = React.createContext(null);


const LazyApp = lazy(() => import("./App"));


// configureDatabase()
ReactDOM.render(
    <StoreContext.Provider value={storeInstance}>
        <Provider store={store}>

            <Suspense fallback={null}>
                <Layout>
                    <LazyApp/>
                </Layout>
            </Suspense>

        </Provider>
    </StoreContext.Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
