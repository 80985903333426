import { toast } from 'react-toastify';
import storeInstance from './store';
import {history }from './history';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
	, debug = process.env.REACT_APP_DEBUG === 'true';

export const propertyAddressToString = (row, index) => {
	return `${row.address.address_one || ''} ${row.address.address_two || ''}, ${row.address.suburb} ${row.address.postcode}, ${row.address.state}, ${row.address.country}`;
};

export function clearUserDetails() {
	localStorage.removeItem('ropematch:user-details');
}

// Get user details
export function getUserDetails() {
	let userDetails = localStorage.getItem('ropematch:user-details');
	return userDetails ? JSON.parse(userDetails) : null;
}

const saveUserDetails = (res) => {
	const {
		token,
		user_id,
		first_name,
		expires,
		last_name,
	} = res;
	const userDetails = JSON.stringify({
		token,
		user_id,
		// name: `${first_name} ${last_name}`,
		// style: style,
		expires,
		first_name,
		last_name,
	});
	localStorage.setItem('ropematch:user-details', userDetails);
	storeInstance.setUserDetails(userDetails);
};

function mobXUserDetails(){
	storeInstance.setUserDetails(getUserDetails());
}
const callApi = async (path, method, body, useCredentials = false) => {
	let options = {
		method,
		headers: {
			'app-version': 'web',
		}
	};
	if (method.toLowerCase() !== 'get') {
		options.body = JSON.stringify(body);
	}
	if (useCredentials) {
		const userDetails = getUserDetails();
		if (!userDetails) {
			return new Error('Missing credentials');
		}
		const { user_id, token } = userDetails;
		options.headers = {
			...options.headers,
			'User-ID': user_id,
			'Authorization': token
		};
	}
	let res;
	try {
		res = await fetch(`${API_BASE_URL}${path}`, options).then(res => res.json());
	} catch (e) {
		debug && console.error(e);
		// toast.error('An unexpected error occurred');
		return new Error('An unexpected error occurred.');
	}
	if (res.error) {
		if (res.error.code === 40001 || res.error.name === 'missing_header') {
			 toast.error('Your Session Expired, Please Sign In.');
			 history.push('/');
			 clearUserDetails();
			 return null;
		}
		if(res.error.code === 40017 || res.error.name === 'not_admin'){
			toast.error('You Do Not Have The Required Privileges.');
			history.push('/');
			clearUserDetails();
			return null;
		}
		debug && console.error(res.error.desc);
		return new Error(res.error.desc);
	}
	return res;
};

export async function login(identifier,password) {
	const res = await callApi('/users/login', 'POST', {
		identifier,
		password,
	});

	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	let validation = await validateAdmin(res.user_id,res.token); //await it next time felix -_- 
	if(!validation){
		history.push("/");
		return null;
	}

	saveUserDetails(res);
	return res;
}

export async function validateAdmin(id,token) {
	let res = await fetch(`${API_BASE_URL}/pending-users`,{
		method: "GET",
		headers: {
			"User-Id": id,
			"Authorization": token
		}

	});
	res = await res.json();
	if(res.error) {
		toast.error('You Do Not Have The Required Privileges.');
		return false;
	}
	return true;

}

export async function logout() {
	const res = await callApi('/users/logout', 'DELETE', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	clearUserDetails();
	return res;
}

export async function validateToken() {
	const res = await callApi('/users/validate', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	mobXUserDetails();
	return res;
}

export async function requestPasswordReset(identifier) {
	const res = await callApi('/users/forgot-password', 'POST', {
		identifier,
	});
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function validateResetToken(token) {
	const res = await callApi('/users/reset-password/validate', 'POST', {
		token,
	});
	if (res instanceof Error) {
		return null;
	}
	return true;
}

export async function resetPassword(token, password, confirmPassword) {
	const res = await callApi('/users/reset-password', 'POST', {
		token,
		password,
		confirm_password: confirmPassword, //if python used camelCase the world would be a better place
	});
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getMembersList(){
	const res = await callApi('/members', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

// this function is used to call API to download a list of entries --> xlsx file
export async function downloadEntries(competition_id) {
	//download the entries
	const userDetails = getUserDetails(); 
    let response = await fetch(API_BASE_URL + "/entries/" + competition_id, {
        method: "GET",
        headers : {
            "User-ID": userDetails.user_id,
            "Authorization" :userDetails.token,
        }
    });

    response = await response.blob();

    return response;
}

export async function getMembersListSpreadsheet(){
	const userDetails = getUserDetails();
	let response = await fetch(API_BASE_URL +"/members/export", {
		headers: {
			"User-Id": userDetails.user_id,
			"Authorization": userDetails.token,
		},
	});

	response = await response.blob();
	if(response.error){
		toast.error(response.error);
		return null
	}

	return response;
}

export async function getPendingList(){
	const res = await callApi('/pending-users', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function approvePending(body){
	const res = await callApi('/approve-user', 'POST', body, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getAllHomescreenCards(){
	const res = await callApi('/home-screen?all', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getHomescreenCards(){
	const res = await callApi('/home-screen', 'GET', null, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}


export async function addHomescreenCard(active,title,preview_text,url_link,detail_text){
	const res = await callApi('/home-screen', 'POST', {
		active,
		title,
		preview_text,
		url_link,
		detail_text,
	}, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}


export async function UpdateHomescreenCard(body){
	const res = await callApi('/home-screen?update', 'POST', body
	, true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getEvents(){
	const res = await callApi('/events','GET',null,true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function addEvent(event_name,description,start_date,end_date){
	const res = await callApi('/events/create','POST',{
		event_name,description,start_date,end_date
	},true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function updateEvent(body){
	const res = await callApi('/events/update','POST',body,true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getSingleEvent(event_id){
	const res = await callApi(`/events/${event_id}`,'GET',null,true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function deleteEvent(event_id){
	const res = await callApi('/event','DELETE',{event_id},true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function getCompetitions(event_id){
	const res = await callApi(`/competitions/${event_id}`,'GET',null,true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function updateCompetition(body){
	const res = await callApi('/competitions/update','POST',body,true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

export async function addCompetition(competitions){
	const res = await callApi('/competition','POST',{competitions},true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}
export async function deleteCompetition(competition_id){
	const res = await callApi('/competition','DELETE',{competition_id},true);
	if (res instanceof Error) {
		toast.error(res.message);
		return null;
	}
	return res;
}

