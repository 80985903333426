import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "cards",
    title: "Homescreen Cards",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/cards",
  },
  {
    id: "events",
    title: "Events & Competitions",
    type: "item",
    icon: <Icon.MapPin size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/events",
  },
  {
    id: "pending",
    title: "Pending Users",
    type: "item",
    icon: <Icon.UserCheck size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/pending-users",
  },
  {
    id: "members",
    title: "Members",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/members",
  },
];

export default horizontalMenuConfig;
